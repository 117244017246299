import {useState} from 'react';
import {Button, Form, Alert} from 'react-bootstrap';

// URL: profitableonlinebusinessideas.com/9onwjZAnmE

export default function LongBuyForm(){
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [success, setSuccess] = useState(false);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        setSuccess(true);

        const pkg = {
        name: name,
        email: email,
        phone: phone,
        }
        
        fetch('/longbuyopt', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-type': 'application/json',
        },
        body: JSON.stringify(pkg)
        })
        .then((res) => res.json())
        .then((data) => {
        console.log("Success", data)
        });

        window.open("https://jonilong.cbhrealty.com/search/#status=active&sold_days=180&pstatus=1%2C11&ls_conversion=acres&location_search_field=Marion%2C%20IA&drive_time=09%3A00&drive_duration=15&drive_avoid_ferry=1&drive_departure=1&ss_locale=en-US&ss_description=Marion%2C%20IA&ss_email_freq=40&ss_send_zero_result=1&bounds_north=42.16024639059721&bounds_east=-91.47310265973432&bounds_south=41.93614994650615&bounds_west=-91.6859627671562&center_lat=42.033279&center_lon=-91.5968958&center_lat_pan=42.048296985266084&center_lon_pan=-91.57953271344526&geotype=PopulatedPlace&user_lat=42.033279&user_lon=-91.5968958&pgsize=20&startidx=0&zoom=12&sort_by=1&company_uuid=3589732&commute=0&buffer_miles=0.25&geospatial=true&agent_uuid=805b6d68-ec6a-4da0-915d-e0d77d784438&ptype=1%2C2%2C9&searchType=criteria&omit_hidden=true&ex_pend=true&currency=USD");
    }

    return(
        <div className="container-fluid opt floydsellform">
                <div className="row" id="opt">
                    <div className="col-12" id="formstyler">
                        <Form onSubmit={handleSubmit}>
                            <h3>Fill Out This Form to Get Information on Available Properties Around Cedar Rapids!</h3>
                            <div className="row">
                                <div className="col-md-4 col-sm-12 optform">
                                    <Form.Group>
                                        <Form.Control required type="text" placeholder="Your Name" onChange={(e) => setName(e.target.value)} />
                                    </Form.Group>
                                </div>
                                <div className="col-md-5 col-sm-12 optform">
                                    <Form.Group>
                                        <Form.Control required type="email" placeholder="youremail@provider.com" onChange={(e) => setEmail(e.target.value)} />
                                    </Form.Group>
                                </div>
                                <div className="col-md-3 col-sm-12 optform">
                                    <Form.Group>
                                        <Form.Control required type="text" placeholder="Phone: (XXX)XXX-XXXX" onChange={(e) => setPhone(e.target.value)} />
                                    </Form.Group>
                                </div>
                            </div>
                            {!success &&
                                <Button variant="secondary" type="submit">Get Started Today!</Button>
                            }
                            {success &&
                                <Button variant="success">Success!</Button>
                            }
                        </Form>
                    </div>
                </div>
        </div>
    )
}