import {useState} from 'react';
import {Button, Form, Alert} from 'react-bootstrap';

// URL: profitableonlinebusinessideas.com/ZF8UHQAqfS

export default function ManfrediSellForm(){
    const [lname, setLname] = useState("");
    const [fname, setFname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [success, setSuccess] = useState(false);
    const [addy, setAddy] = useState("");
    const [aptnum, setAptnum] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        setSuccess(true);

        const pkg = {
            fname: fname,
            lname: lname,
            email: email,
            phone: phone,
            addy: addy,
            aptnum: aptnum,
            city: city,
            state: state,
            zip: zip,
        }
        
        fetch('/manfredisellopt', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-type': 'application/json',
        },
        body: JSON.stringify(pkg)
        })
        .then((res) => res.json())
        .then((data) => {
        console.log("Success", data)
        });
    }

    return(
        <div className="container-fluid opt manform">
                <div className="row" id="opt">
                    <div className="col-12 text-center" id="formstyler">
                        <Form onSubmit={handleSubmit}>
                            {!success &&
                            <>
                            <div className="row">
                                <div className="col-md-5 col-sm-12 optform">
                                    <Form.Group>
                                        <Form.Control required type="text" placeholder="Street Address" onChange={(e) => setAddy(e.target.value)} />
                                    </Form.Group>
                                </div>
                                <div className="col-md-5 col-sm-12 optform">
                                    <Form.Group>
                                        <Form.Control required type="text" placeholder="Preferred Contact Email" onChange={(e) => setEmail(e.target.value)} />
                                    </Form.Group>
                                </div>
                                <div className="col-md-2 col-sm-12 optform">
                                    <Button variant="secondary" type="submit">Get Estimate</Button>
                                </div>
                            </div>
                            </>
                            }
                            {success &&
                            <div className="row">
                                <div className="col-12">
                                    <h2>Mike will get back to you as soon as possible with your estimate.</h2>
                                </div>
                            </div>
                            }
                        </Form>
                    </div>
                </div>
        </div>
    )
}