import {useState} from 'react';
import {Button, Form, Alert} from 'react-bootstrap';

// URL: profitableonlinebusinessideas.com/BD2xjn4YEa

export default function DigiForm(){
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [success, setSuccess] = useState(false);
    const [complete, setComplete] = useState(false);
    const [msg, setMsg] = useState("");
    
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        setSuccess(true);

        const pkg = {
        name: name,
        email: email,
        phone: phone,
        msg: msg
        }

        fetch('/digiopt', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-type': 'application/json',
        },
        body: JSON.stringify(pkg)
        })
        .then((res) => res.json())
        .then((data) => {
        console.log("Success", data)
        });

        window.open("https://homesforsalefortcollins.com/search.html", "_blank");
    }

    return(
        <div className="container-fluid opt digi">
                <div className="row" id="opt">
                    <div className="col-12" id="formstyler">
                        <Form onSubmit={handleSubmit}>
                            <h2>Fill Out This Form to Get Started!</h2>
                            <div className="row">
                                <div className="col-4 optform">
                                    <Form.Group>
                                        <Form.Control required type="text" placeholder="Your Name" onChange={(e) => setName(e.target.value)} />
                                    </Form.Group>
                                </div>
                                <div className="col-5 optform">
                                    <Form.Group>
                                        <Form.Control required type="email" placeholder="youremail@provider.com" onChange={(e) => setEmail(e.target.value)} />
                                    </Form.Group>
                                </div>
                                <div className="col-3 optform">
                                    <Form.Group>
                                        <Form.Control required type="text" placeholder="(XXX)XXX-XXXX" onChange={(e) => setPhone(e.target.value)} />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-1"></div>
                                <div className="col-10">
                                    <Form.Group>
                                        <Form.Control as="textarea" placeholder="Message for Felicia (Optional)" onChange={(e) => setMsg(e.target.value)} className="w-100" />
                                    </Form.Group>
                                </div>
                                <div className="col-1"></div>
                            </div>
                            {!success && (typeof phone == "string") &&
                                <Button variant="secondary" type="submit">Get Started Today!</Button>
                            }
                            {success &&
                                <>
                                <div className="col-2"></div>
                                <div className="col-8 succeed">
                                    <h4 className="successmsg">Thank you for your interest in Fort Collins Homes. We will respond to your request as soon as we are able.</h4>
                                </div>
                                <div className="col-2"></div>
                                </>
                            }
                        </Form>
                    </div>
                </div>
        </div>
    )
}