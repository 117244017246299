import {useState} from 'react';
import {Button, Form, Alert} from 'react-bootstrap';

// URL: profitableonlinebusinessideas.com/ILOF1sNeVB

export default function WattsBuyForm(){
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [success, setSuccess] = useState(false);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        setSuccess(true);

        const pkg = {
        name: name,
        email: email,
        phone: phone,
        }
        
        fetch('/wattsbuyopt', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-type': 'application/json',
        },
        body: JSON.stringify(pkg)
        })
        .then((res) => res.json())
        .then((data) => {
        console.log("Success", data)
        });

        window.open("https://www.pascocountyforsale.com/thank-you.html", "_blank");
    }

    return(
        <div className="container-fluid opt wattssellform">
                <div className="row" id="opt">
                    <div className="col-12" id="formstyler">
                        <Form onSubmit={handleSubmit}>
                            <h3 style={{"margin":"auto", "maxWidth":"fit-content", "textAlign":"center"}}>Fill out the form below and Connect with REALTOR&reg; Anthoneal Watts to get your Real Estate goals in motion TODAY!</h3>
                            <div className="row" style={{"marginTop":"25px"}}>
                                <div className="col-md-4 col-sm-12 optform">
                                    <Form.Group>
                                        <Form.Control required type="text" placeholder="Your Name" onChange={(e) => setName(e.target.value)} />
                                    </Form.Group>
                                </div>
                                <div className="col-md-5 col-sm-12 optform">
                                    <Form.Group>
                                        <Form.Control required type="email" placeholder="youremail@provider.com" onChange={(e) => setEmail(e.target.value)} />
                                    </Form.Group>
                                </div>
                                <div className="col-md-3 col-sm-12 optform">
                                    <Form.Group>
                                        <Form.Control required type="text" placeholder="Phone: (XXX)XXX-XXXX" onChange={(e) => setPhone(e.target.value)} />
                                    </Form.Group>
                                </div>
                            </div>
                            {!success &&
                                <Button variant="secondary" type="submit">Get Started Today!</Button>
                            }
                            {success &&
                                <Button variant="success">Success!</Button>
                            }
                            <div className="row" style={{"textAlign":"center"}}>
                                <p>By proceeding, you consent to be contacted by Pascocountyforsale.com and its Affiliated Companies. By doing so, you agree to receive communications from us, including emails, text messages, phone calls, marketing by auto dialer and artificial voice, and other forms of communication. You understand and agree that you may be contacted to receive information about your inquiry, home-related matters, our services, but not as a condition of any purchase; this applies regardless of whether you check, or leave un-checked, any box or field above.  This consent applies even if you are on a corporate, state or national Do Not Call list. Thank you for opting-in.</p>
                            </div>
                        </Form>
                    </div>
                </div>
        </div>
    )
}