import {useState} from 'react';
import {Button, Form, Alert} from 'react-bootstrap';

// URL: profitableonlinebusinessideas.com/cCFW0FXqth

export default function IsleForm(){
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [success, setSuccess] = useState(false);
    const [time, setTime] = useState("");
    const [zone, setZone] = useState("");
    const [msg, setMsg] = useState("");
    const [math, setMath] = useState("");
    const [meet, setMeet] = useState([]);
    const [mathcheck, setCheck] = useState("3331");

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        setSuccess(true);

        const pkg = {
        name: name,
        email: email,
        phone: phone,
        time: time,
        zone: zone,
        msg: msg,
        meet: meet
        }
        
        fetch('/isleopt', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-type': 'application/json',
        },
        body: JSON.stringify(pkg)
        })
        .then((res) => res.json())
        .then((data) => {
        console.log("Success", data)
        });

        window.open("https://wealthwave.com/danielmoney", "_blank");
    }

    const checkfilter = (term, arr) => {
        console.log("Term: " + term);
        console.log("Array: " + arr);
        let filtArr = arr.filter(function(e) {return e !== term});
        console.log(filtArr);
        return filtArr;
    }

    const boxchange = (event) => {
        let updater = event.target.getAttribute('id');
        console.log(updater);
        console.log(meet);
        let upmeet = meet;
        if (!meet.includes(updater)){
            meet.push(updater);
        } else {
            upmeet = checkfilter(updater, meet);
            console.log(upmeet);
        }
        console.log(upmeet);
        setMeet(upmeet);
    }

    return(
        <div className="container-fluid opt ctsform">
                <div className="row" id="opt">
                    <div className="col-12" id="formstyler">
                        <Form onSubmit={handleSubmit}>
                            <h2>Contact Me to Set Up an Appointment Today!</h2>
                            <div className="row">
                                <div className="col-md-4 col-sm-12 optform">
                                    <Form.Group>
                                        <Form.Control required type="text" placeholder="Your Name" onChange={(e) => setName(e.target.value)} />
                                    </Form.Group>
                                </div>
                                <div className="col-md-5 col-sm-12 optform">
                                    <Form.Group>
                                        <Form.Control required type="email" placeholder="youremail@provider.com" onChange={(e) => setEmail(e.target.value)} />
                                    </Form.Group>
                                </div>
                                <div className="col-md-3 col-sm-12 optform">
                                    <Form.Group>
                                        <Form.Control required type="text" placeholder="Phone: (XXX)XXX-XXXX" onChange={(e) => setPhone(e.target.value)} />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-sm-1"></div>
                                <div className="col-md-6 col-sm-5 optform">
                                    <Form.Group>
                                        <Form.Control required as="select" onChange={(e) => setTime(e.target.value)}>
                                            <option>Select Contact Time</option>
                                            <option>Morning (8 AM - Noon)</option>
                                            <option>Afternoon (Noon - 6 PM)</option>
                                            <option>Evening (6 PM - 10 PM)</option>
                                        </Form.Control>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6 col-sm-5 optform">
                                    <Form.Group>
                                        <Form.Control required as="select" onChange={(e) => setZone(e.target.value)}>
                                            <option>Select Time Zone</option>
                                            <option>EST (Eastern US)</option>
                                            <option>CST (Central US)</option>
                                            <option>MST (Mountain US)</option>
                                            <option>PST (Pacific US)</option>
                                        </Form.Control>
                                    </Form.Group>
                                </div>
                                <div className="col-md-3 col-sm-1"></div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-sm-1"></div>
                                <div className="col-md-6 col-sm-10 optform">
                                    <h5>What Do You Want to Meet About? (Mark all that apply.)</h5>
                                    <Form.Group>
                                        <Form.Check type="switch" onChange={(e) => boxchange(e)} id="Asset Protection" label="Asset Protection" />
                                        <Form.Check type="switch" onChange={(e) => boxchange(e)} id="Key Man Insurance" label="Key Man Insurance" />
                                        <Form.Check type="switch" onChange={(e) => boxchange(e)} id="Financial Strategies" label="Financial Strategies" />
                                        <Form.Check type="switch" onChange={(e) => boxchange(e)} id="Financial Education Business" label="Financial Education Business" />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-1"></div>
                                <div className="col-md-4 col-sm-10">
                                    <Form.Group>
                                        <Form.Control required type="text" placeholder="Type 3331 to Continue" onChange={(e) => setMath(e.target.value)} />
                                    </Form.Group>
                                </div>
                                <div className="col-md-4 col-sm-1"></div>
                            </div>
                            {math != mathcheck &&
                                <Alert variant="danger">Complete the Form to Continue</Alert>
                            }
                            {!success && math == mathcheck &&
                                <Button variant="primary" type="submit">Get Started Today!</Button>
                            }
                            {success &&
                                <Button variant="success">Success!</Button>
                            }
                        </Form>
                    </div>
                </div>
        </div>
    )
}